import React, {useEffect, useState} from 'react'
import moment from 'moment'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import {getExchangeRate} from "./exchange-rate";

const labelStyle = {textAlign: 'right', width: '180px', fontWeight: 'bold', borderBottom: '1px solid black'}
const suffixStyle = {textAlign: 'left', width: '30px', borderBottom: '1px solid black'}

const urlSearchParams = new URLSearchParams(window.location.search)

const APP_COST_PER_KG = 0.007
const APP_TYPE_COST_PER_KG = {
    UNGRADED: 0,
    OIL: 0.001,
    REJECTS: 0.001,
    GREEN_SKINS: 0.001
}
const Statistics = ({fromDate, grower, days, toDate}) => {

    const [exchangeRate, setExchangeRate] = useState({currency: 'Tsh', rate: 1})
    const [statistics, setStatistics] = useState(null)

    useEffect(() => {
        const from = fromDate.format('YYYY-MM-DD')
        const queries = [`fromDate=${from}`]
        let to
        if (toDate) {
            to = toDate.format('YYYY-MM-DD')
            queries.push(`toDate=${to}`)
        } else {
            to = moment(fromDate).add(days, 'days').format('YYYY-MM-DD')
            queries.push(`toDate=${to}`)
        }
        if (grower) {
            queries.push(`grower=${grower}`)
        }
        const queryParams = queries.join('&')
        getExchangeRate().then(setExchangeRate)
        fetch(`/api/fruit/harvests/statistics?${queryParams}`)
            .then(response => response.json())
            .then(setStatistics)
    }, [setStatistics, setExchangeRate, fromDate, toDate, grower, days])

    const valueStyle = {
        textAlign: 'right',
        fontFamily: 'monospace',
        width: '150px',
        borderBottom: '1px solid black'
    }

    if (statistics) {
        const totalCost = Math.round(statistics.totalCost)
        const totalPaid = Math.round(statistics.totalPaid)

        const totalRemaining = totalCost - totalPaid
        const totalHarvested = Math.round(statistics.totalHarvested / 1000)
        const totalReceived = Math.round(statistics.totalReceived / 1000)
        const costStats = []
        const paymentStats = [
            {
                label: 'Cost',
                values: [{value: totalCost, suffix: 'Tsh'}, {
                    value: totalCost * exchangeRate.rate,
                    suffix: exchangeRate.currency
                }]
            },
            {
                label: 'Paid',
                values: [{value: totalPaid, suffix: 'Tsh'}, {
                    value: totalPaid * exchangeRate.rate,
                    suffix: exchangeRate.currency
                }]
            },
            {
                label: 'Remaining',
                values: [{value: totalRemaining, suffix: 'Tsh'}, {
                    value: totalRemaining * exchangeRate.rate,
                    suffix: exchangeRate.currency
                }]
            }
        ]

        if (urlSearchParams.get('includeAppCost')) {
            let totalNewCost = 0
            Object.keys(statistics.totalHarvestedGrouped)
                .sort((a, b) => a.localeCompare(b))
                .filter(type => statistics.totalHarvestedGrouped[type] > 0)
                .map(type => {
                    const cost = APP_TYPE_COST_PER_KG[type] || APP_COST_PER_KG
                    const kilos = Math.round(statistics.totalHarvestedGrouped[type] / 1000)
                    const appCostInUsd = kilos * cost
                    totalNewCost += appCostInUsd
                    return {
                        label: `${type} cost @ $${cost}/kg`,
                        values: [{value: kilos, suffix: 'kg'},
                            {
                                value: appCostInUsd,
                                suffix: 'USD'
                            }]
                    }
                })
                .forEach(cost => costStats.push(cost))
            costStats.push(
                {
                    label: `App Cost @ $various/kg)`,
                    values: [{value: totalHarvested, suffix: 'kg'}, {
                        value: totalNewCost,
                        suffix: 'USD'
                    }]
                })
        }

        const stats = [
            {
                label: 'Harvested',
                value: totalHarvested,
                suffix: 'kg'
            },
            ...Object.keys(statistics.totalHarvestedGrouped)
                .sort((a, b) => a.localeCompare(b))
                .filter(type => statistics.totalHarvestedGrouped[type] > 0)
                .map(type => {
                    const kilos = Math.round(statistics.totalHarvestedGrouped[type] / 1000)
                    return {
                        label: `${type}`, value: kilos, suffix: 'kg'
                    }
                }),
            {label: 'Received', value: totalReceived, suffix: 'kg'},
            {label: 'Harvested Crates', value: statistics.totalCrates},
            {label: 'Received Crates', value: statistics.receivedCrates}
        ]

        return (
            <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
                <div style={{flexGrow: 1}}>
                    <table className="table table-striped">
                        <tbody>
                        {
                            stats.map((stat, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={labelStyle}>{stat.label}</td>
                                        <td style={valueStyle}>{numeral(stat.value).format('0,0')}</td>
                                        <td style={suffixStyle}>{stat.suffix}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div style={{flexGrow: 1}}>
                    <table className="table table-striped">
                        <tbody>
                        {
                            paymentStats.map((stat, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={labelStyle}>{stat.label}</td>
                                        {(stat.values || [{value: stat.value, suffix: stat.suffix}])
                                            .map((value, vIndex) => {
                                                return <React.Fragment key={vIndex}>
                                                    <td style={valueStyle}>{numeral(value.value).format('0,0')}</td>
                                                    <td style={suffixStyle}>{value.suffix}</td>
                                                </React.Fragment>
                                            })}
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {!!costStats.length && (
                    <div style={{flexGrow: 1}}>
                        <table className="table table-striped">
                            <tbody>
                            {
                                costStats.map((stat, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={labelStyle}>{stat.label}</td>
                                            {(stat.values || [{value: stat.value, suffix: stat.suffix}])
                                                .map((value, vIndex) => {
                                                    return <React.Fragment key={vIndex}>
                                                        <td style={valueStyle}>{numeral(value.value).format('0,0')}</td>
                                                        <td style={suffixStyle}>{value.suffix}</td>
                                                    </React.Fragment>
                                                })}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>)}
            </div>
        )
    } else {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }
}

export default Statistics

Statistics.propTypes = {
    fromDate: PropTypes.object.isRequired,
    toDate: PropTypes.object,
    grower: PropTypes.string
}

