let cache = null;

export const getExchangeRate = async () => {
    if (cache) {
        return cache;
    }
    const response = await fetch('/api');
    if (!response.ok) {
        return false;
    }
    const {exchangeRate} = await response.json();
    cache = {rate: exchangeRate, currency: 'USD'};
    return cache;
}
